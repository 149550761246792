.titulo {
  display: flex;
  position: relative;
  transition: all 1s;
  margin-bottom: 2em;
  margin-top: 3em;
}


.titulo img {
  width: 120px;
  height: auto;
  transition: all 1s;
}

.titulo h3 {
  font-family: var(--fonte-titulo);
  font-weight: 700;
  font-size: 1.5em;
  color: var(--cor-escura);
  transition: all 1s;
}

.titulo h3::selection {
  background-color: var(--cor-escura);
  color: var(--cor-destaque2);
}


@keyframes teste {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}

@keyframes rotate {
  from {
    rotate: 360deg;
  }
  to {
    rotate: -360deg;
  }
}

@keyframes view {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

