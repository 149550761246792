.simbolo {
    width: 1em;
    height: auto;
    rotate: 0deg;
    animation: girar 2s infinite;
    transition: ease-in-out;
    margin-left: 8px;
}

@keyframes girar {
    0% {rotate: 0deg}
    100%{rotate: 360deg;}
}