.topico {
    display: flex;
    align-items: center;
    padding: 16px 0;

}

.topico img {
    width: 32px;
    height: auto;
    padding: 8px;
}

.topico p {
    font-family: var(--fonte-padrao);
    color: var(--cor-escura);
    text-align: left;
    padding: 8px;
}

.topico p::selection {
    background-color: var(--cor-destaque1);
    color: var(--cor-clara);
}